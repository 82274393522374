<template>
    <div>
        <!--begin::Body-->
        <div class="kt-login__body">
            <!--begin::Signin-->
            <div class="kt-login__form">
                <div class="kt-login__title">
                    <h3>{{ $t("AUTH.LOGIN.TITLE") }}</h3>
                </div>

                <!--begin::Form-->
                <b-form class="kt-form" @submit.stop.prevent="onSubmit">
                    <div role="alert" class="alert alert-info">
                        <div class="alert-text">
                            {{ $t("AUTH.LOGIN.DESCRIPTION") }}
                        </div>
                    </div>

                    <div
                        role="alert"
                        v-bind:class="{ show: errors.length }"
                        class="alert fade alert-danger"
                    >
                        <div
                            class="alert-text"
                            v-for="(error, i) in errors"
                            :key="i"
                        >
                            {{ error }}
                        </div>
                    </div>

                    <div
                        role="alert"
                        v-bind:class="{ show: successMessage }"
                        class="alert fade alert-success"
                    >
                        <div class="alert-text">
                            {{ successMessage }}
                        </div>
                    </div>

                    <b-form-group
                        id="email-input-group"
                        label=""
                        label-for="email-input"
                    >
                        <b-form-input
                            id="email-input"
                            name="username"
                            v-model="$v.form.email.$model"
                            :state="validateState('email')"
                            aria-describedby="email-input-live-feedback"
                        ></b-form-input>

                        <b-form-invalid-feedback id="email-input-live-feedback">
                            {{ $t("AUTH.LOGIN.VALID_EMAIL") }}
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group
                        id="password-input-group"
                        label=""
                        label-for="password-input"
                    >
                        <b-form-input
                            type="password"
                            id="password-input"
                            name="password"
                            v-model="$v.form.password.$model"
                            :state="validateState('password')"
                            aria-describedby="input-live-feedback"
                        ></b-form-input>

                        <b-form-invalid-feedback id="input-live-feedback">
                            {{ $t("AUTH.LOGIN.VALID_PASSWORD") }}
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <!--begin::Action-->
                    <div class="kt-login__actions">
                        <router-link to="/forgot">
                            <a class="kt-link kt-login__link-forgot">
                                {{ $t("AUTH.FORGOT.TITLE") }}
                            </a>
                        </router-link>
                        <GoogleLoginButton
                          @loginResponse="sendLoginRequest($event, 'google')"
                        />
                        <b-button
                            type="submit"
                            id="kt_submit"
                            class="btn btn-primary btn-elevate kt-login__btn-primary"
                        >
                            {{ $t("AUTH.LOGIN.BUTTON") }}
                        </b-button>
                    </div>
                    <!--end::Action-->
                </b-form>
                <!--end::Form-->
            </div>
            <!--end::Signin-->
        </div>
        <!--end::Body-->
    </div>
</template>

<style lang="scss" scoped>
.kt-spinner.kt-spinner--right:before {
    right: 8px;
}
</style>

<script>
import { mapGetters, mapState } from "vuex";
import { LOGIN, GOOGLE_LOGIN } from "@/store/auth.module";
import { validationMixin } from "vuelidate";
import GoogleLoginButton from "@/views/partials/form/GoogleLoginButton.vue"
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
    components: { GoogleLoginButton },
    mixins: [validationMixin],
    name: "login",
    data() {
        return {
            form: {
                email: "",
                password: ""
            }
        };
    },
    mounted() {
        // check if current user is authenticated
        if (this.isAuthenticated) {
            this.$router.push({ name: "offer" });
        }
    },
    validations: {
        form: {
            email: {
                required,
                email
            },
            password: {
                required,
                minLength: minLength(3)
            }
        }
    },
    methods: {
      validateState(name) {
          const { $dirty, $error } = this.$v.form[name];
          return $dirty ? !$error : null;
      },

      resetForm() {
          this.form = {
              email: null,
              password: null
          };

          this.$nextTick(() => {
              this.$v.$reset();
          });
      },

      onSubmit() {
          this.$v.form.$touch();
          if (this.$v.form.$anyError) {
              return;
          }

          const email = this.$v.form.email.$model;
          const password = this.$v.form.password.$model;

          this.sendLoginRequest({ username: email, password: password });
      },

      sendLoginRequest(params, type = 'form') {
        // set spinner to submit button
        const submitButton = document.getElementById("kt_submit");
        submitButton.classList.add(
            "kt-spinner",
            "kt-spinner--light",
            "kt-spinner--right"
        );

        let method = type === 'google' ? GOOGLE_LOGIN : LOGIN;

        // send login request
        this.$store
            .dispatch(method, params)
            // go to which page after successfully login
            .then(() => {
                this.$router.push({ name: "offer" });
            })
            .finally(() => {
                submitButton.classList.remove(
                    "kt-spinner",
                    "kt-spinner--light",
                    "kt-spinner--right"
                );
            });
      }
    },
    computed: {
        ...mapGetters(["isAuthenticated","layoutConfig"]),
        ...mapState({
            errors: state => state.auth.errors,
            successMessage: state => state.auth.successMessage
        }),
        backgroundImage() {
            return process.env.BASE_URL + "assets/media/bg/bg-4.jpg";
        }
    }
};
</script>
